/** @jsx jsx */
// import React from "react";
import { jsx, Box, Text } from "theme-ui";

export default () => {
  return (
    <Box as="section">
      <Box sx={{ m: "auto" }}>
        <Text sx={{ textAlign: "center" }}>
          ČO SU COOKIES A PREČO SU POUŽIVANÉ
        </Text>

        <Box sx={{ mx: "auto" }}>
          S cieľom zabezpečiť riadne fungovanie tejto stránky ukladáme niekedy
          na vašom zariadení malé dátové súbory, tzv. cookie. Ide o malý textový
          súbor, pomocou ktorého si naša stránka na určitý čas uchováva
          informácie o vašich krokoch a preferenciách.
        </Box>

        <Text sx={{ textAlign: "center" }}>
          COOKIES NUTNÉ K FUNGOVANIU TEJTO STRÁNKY A BEZ POUŽITIA OSOBNÝCH
          ÚDAJOV
        </Text>

        <Box sx={{ mx: "auto" }}>
          Jediné cookies, ktoré naša stránka používa sú analytické cookies
          Google Analytics pre získavanie anonymizovaných štatistík o používaní
          našej stránky, ktoré sú uchovavané po dobu 24 mesiacov. Služba je
          poskytovaná firmou Google Ireland Limited Gordon House, Barrow Street,
          Dublin 4, Ireland.
        </Box>
      </Box>
    </Box>
  );
};
